.footer-text {
  padding-left: 25px;
  color: #868e96;
  font-size: 0.5em;
}

.footer-div {
  margin-top: 2rem;
}

.footer-url {
  width: 1000px;
  font-size: 1em;
  color: #868e96;
}
